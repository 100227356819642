exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-lesson-template-js": () => import("./../../../src/templates/lessonTemplate.js" /* webpackChunkName: "component---src-templates-lesson-template-js" */),
  "component---src-templates-library-template-js": () => import("./../../../src/templates/libraryTemplate.js" /* webpackChunkName: "component---src-templates-library-template-js" */),
  "component---src-templates-news-main-template-js": () => import("./../../../src/templates/newsMainTemplate.js" /* webpackChunkName: "component---src-templates-news-main-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-nko-template-js": () => import("./../../../src/templates/nkoTemplate.js" /* webpackChunkName: "component---src-templates-nko-template-js" */),
  "component---src-templates-quiz-template-js": () => import("./../../../src/templates/quizTemplate.js" /* webpackChunkName: "component---src-templates-quiz-template-js" */),
  "component---src-templates-video-library-template-js": () => import("./../../../src/templates/videoLibraryTemplate.js" /* webpackChunkName: "component---src-templates-video-library-template-js" */)
}

